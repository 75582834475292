<!-- 新闻 -->
<template>
	<div style="width: 100%;max-width: 100%;">
		<div class="news">
			<tap v-if="taps[2]" :taps="[taps[2].catname]" :tapIndex="tapIndex" @more="clkMoNewgg"></tap>
			<div class="cotain"  v-if="newgg.length">
				<div class="swiper-container">
					<div class="swiper-wrapper">
						<div class="swiper-slide" v-for="(item,index) in newgg" :key="item.id" v-if="index<3" @click="clkTz(item.id)">
							<img :src="item.thumb" alt="">
						</div>
					</div>
					<div class="tle">
						<div>{{newgg[newIndex].title}}</div>
						<div class="circle">
							<div class="cle-item"  v-for="(item,index) in newgg" :key="item.id" v-if="index<3" :class="newIndex==index?'active':''"></div>
						</div>
					</div>
				</div>
				<div class="right-ctx">
					<div class="ctx-item" v-for="(item,index) in newgg" :key="item.id" v-if="index>0 && index<6" @click="clkTz(item.id)">
						<div class="ctx-tle">
							<span class="tle">{{item.title}}</span>
							<span class="time">{{item.inputtime}}</span>
						</div>
						<div class="cota">{{item.description}}</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class=" c-news">
			<div class="c-news-item" v-if="djwork.length">
				<tap v-if="taps[0]" :taps="[taps[0].catname]" @more="clkMoNewzxdt" @clkTap="clkTap"></tap>
				<template>
					<div style="overflow: hidden;"  @click="clkNewDail(djwork[0].id)">
						<img :src="djwork[0].thumb" alt="">
					</div>
					<div class="title"  @click="clkNewDail(djwork[0].id)">{{djwork[0].title}}</div>
					<div class="list-box">
						<div class="list-item" v-for="(i,index) in djwork" v-if="index>0" :key="i.id" @click="clkNewDail(i.id)">
							<div class="text">{{i.title}}</div>
							<div class="time">{{i.inputtime}}</div>
						</div>
					</div>
				</template>
			</div>
			<div class="c-news-item c-news-item2" v-if="tzNtc.length">
				<tap v-if="taps[1]" :taps="[taps[1].catname]" @more="clkMoTzgg"></tap>
				<template>
					<div style="overflow: hidden;" @click="clkTz(tzNtc[0].id)">
						<img :src="tzNtc[0].thumb" alt="">
					</div>
					<div class="title" @click="clkTz(tzNtc[0].id)">{{tzNtc[0].title}}</div>
					<div class="list-box">
						<div class="list-item" v-for="(i,index) in tzNtc" :key="i.id" v-if="index>0" @click="clkTz(i.id)">
							<div class="text">{{i.title}}</div>
							<div class="time">{{i.inputtime}}</div>
						</div>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
	import qs from "qs"
	export default {
		data() {
			return {
				// 新闻列表轮播图
				newIndex:0,
				// // 新闻公告
				newgg:[],
				// // 中心动态
				// newNtc:[],
				// // 通知公告
				tzNtc:[],
				// // 党建活动
				
				
				//中心动态
				djwork: [],
				//党建活动
				lzjs: [],
				
				taps: [],
				tapIndex: 0,
			}
		},
		created() {
			// 新闻公告
			this.getWord()
			// 中心动态
			// this.getNew()
			// 通知公告
			this.getTzGg()
			
			//新闻公告
			this.LeftSidebar(17)
			//中心动态
			this.getddjs(114)
			//党建活动
			this.getTjyw()
		},
		methods: {
			
			clkTap(index, id) {
				this.tapIndex = index
				this.getddjs(id)
			},
			
			// 点击新闻公告更多
			clkMoNewgg() {
				let url = "Aboutus/news_center"
				let catid = 202
				let index = this.$route.query.index
				this.$router.push({name:"newMore",query:{index,url,catid,tap:this.taps[2].catname}})
			},
			// 点击中心动态更多
			clkMoNewzxdt() {
				let url = "Aboutus/news_center"
				let catid = 114
				let index = this.$route.query.index
				this.$router.push({name:"newMore",query:{index,url,catid,tap:this.taps[0].catname}})
			},
			// 点击党建活动
			clkMoTzgg() {
				let url = "Aboutus/news_center"
				let catid = 115
				let index = this.$route.query.index
				this.$router.push({name:"newMore",query:{index,url,catid,tap:this.taps[1].catname}})
			},
			//===============================================================================
			
			
			// 点击通知公告
			clkTz(id) {
				let index = this.$route.query.index
				this.$router.push({name:"newdetails",query:{index,id}})
			},
			
			// 轮播图
			getSwiper() {
				let that = this
				setTimeout(()=>{
					new Swiper('.swiper-container', {
						pagination: '.swiper-pagination', //分页器
						loop: true, //循环
						paginationClickable:true, //分页器点击
						autoplay: 5000, //时长
						speed:600,
						grabCursor:true,
						autoplayDisableOnInteraction : false,
						onSlideChangeEnd: function(swiper){
							that.newIndex = swiper.realIndex
						}
					})
				},1000)
			},
			
			// 查看新闻详情
			clkNewDail(id) {
				let index = this.$route.query.index
				this.$router.push({name:"newdetails",query:{index,id,type:1}})
			},
			
			//左边导航栏
			LeftSidebar(nav_id) {
				let url = this.$api.about.LeftSidebar
				let data = {
					nav_id
				}
				this.$axios.post(url, qs.stringify(data)).then(res => {
					if (res.code) {
						this.taps = res.list
					}
				})
			},
			
			// 查找新闻公告
			async getddjs(catid) {
				let url = this.$api.about.news_center
				let data = {
					catid,
					page: 1,
					limit: 6
				}
				let res = await this.$axios.post(url, qs.stringify(data))
				if (res.code) {
					if (catid == 115) {
						this.lzjs = res.data.data
						console.log('this.lzjs',this.lzjs)
					}
				}
			},
			
			// 查找党建
			getTjyw() {
				this.$axios.get("Aboutus/news_center",{
					params:{
						catid:114,
						page:1,
						limit:6
					}
				}).then(res=>{
					if(res.code) {
						this.djwork = res.data.data
					}
				})
			},
			
			// 查找中心公告
			async getWord() {
				let data = {
					catid:202,
					page:1,
					limit:6
				}
				let res = await this.$axios.post("Aboutus/news_center",qs.stringify(data))
				if(res.code) {
					this.newgg = res.data.data
					this.$nextTick(()=>{
						this.getSwiper()
					})
				}
			},
			
			// 查询通知公告
			getTzGg() {
				let data = {
					catid:115,
					page:1,
					limit:7
				}
				this.$axios.post("Aboutus/news_center",qs.stringify(data)).then(res=>{
					console.log(res,"PPPPPPP");
					this.tzNtc = res.data.data
				})
			}
		}
	};
</script>

<style lang="less" scoped>
	.news {
		.cotain {
			display: flex;
			justify-content: space-between;
			.swiper-container {
				width: 5.24em;
				height: 3.51em;
				flex-shrink: 0;
				position: relative;
				.swiper-wrapper {
					.swiper-slide {
						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}
				}
				.tle {
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					background-color: rgba(0,0,0,.8);
					color: white;
					font-size: 12px;
					font-weight: 400;
					z-index: 999;
					padding: 15px 16px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					&>div:first-child {
						display: -webkit-box;
						overflow: hidden;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 1;
						width: 70%;
					}
					.circle {
						display: flex;
						.cle-item {
							width: 10px;
							height: 10px;
							border-radius: 50%;
							box-sizing: border-box;
							border: 1px solid white;
							margin-right: 5px;
							&:last-child {
								margin-right: 0;
							}
						}
						.active {
							background-color: var(--themeColor);
						}
					}
				}
			}
			.right-ctx {
				flex-grow: 1;
				font-size: 16px;
				display: grid;
				grid-template-rows: repeat(5,1fr);
				margin-left: 30px;
				.ctx-item {
					display: flex;
					flex-direction: column;
					justify-content: center;
					border-bottom: 0.5px solid rgba(235, 236, 239, 1);
					cursor: pointer;
					&:hover {
						.ctx-tle {
							.tle {
								color: var(--themeColor);
								font-weight: bold;
							}
							.time {
								color: var(--themeColor);
								font-weight: bold;
							}
						}
						.cota {
							color: var(--themeColor);
							font-weight: bold;
						}
					}
					.ctx-tle {
						display: flex;
						align-items: baseline;
						justify-content: space-between;
						.tle {
							color: rgba(51, 51, 51, 1);
							font-size: 18px;
							font-weight: 500;
							display: -webkit-box;
							overflow: hidden;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 1;
							padding-right: 60px;
						}
						.time {
							color: rgba(154, 160, 171, 1);
							font-size: 14px;
							font-weight: 400;
							flex-shrink: 0;
						}
					}
					.cota {
						color: rgba(93, 103, 121, 1);
						font-size: 14px;
						line-height: 21px;
						font-weight: 400;
						margin-top: 10px;
						display: -webkit-box;
						overflow: hidden;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
					}
				}
			}
		}
	}
	
	.c-news {
		display: flex;
		justify-content: space-between;
		margin-top: 0.6em;
		.c-news-item {
			width: 5.24em;
			img {
				width: 100%;
				height: 2.4em;
				object-fit: cover;
				cursor: pointer;
				transition: all .5s;
				&:hover {
					transform: scale(1.1);
				}
			}
			.title {
				font-size: 18px;
				font-weight: 500;
				color: rgba(51, 51, 51, 1);
				line-height: 27px;
				padding: 20px 0;
				cursor: pointer;
				transition: all .2s;
				&:hover {
					color: var(--themeColor);
					text-decoration: underline;
				}
			}
			.list-box {
				.list-item {
					display: flex;
					justify-content: space-between;
					padding: 15px 0;
					border-top: 0.5px solid rgba(235, 236, 239, 1);
					cursor: pointer;
					&:hover {
						.text {
							color: var(--themeColor);
							text-decoration: underline;
						} 
						.time {
							color: var(--themeColor);
						}
					}
					&:last-child {
						border-bottom: 0.5px solid rgba(235, 236, 239, 1);
					}
					.text {
						font-size: 14px;
						font-weight: 400;
						color: rgba(102, 102, 102, 1);
						width: 70%;
						display: -webkit-box;
						overflow: hidden;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 1;
					}
					.time {
						font-size: 14px;
						font-weight: 400;
						color: rgba(102, 102, 102, 1);
					}
				}
			}
		}
	}
	
	.activity {
		margin-top: 0.6em;
		.list-box {
			display: grid;
			grid-template-columns: repeat(3,1fr);
			column-gap: 20px;
			row-gap: 30px;
			.list-item {
				transition: all .5s;
				cursor: pointer;
				&:hover {
					box-shadow: 0 0 10px rgba(0,0,0,.3);
					transform: translateY(-5px);
					img {
						transform: scale(1.1);
					}
					.title {
						color: var(--themeColor);
					}
				}
				.img {
					overflow: hidden;
					img {
						width: 100%;
						height: 2.06em;
						object-fit: cover;
						transition: all .5s;
					}
				}
				.title {
					font-size: 18px;
					font-weight: 500;
					padding: 20px;
					border:0.5px solid rgba(235, 236, 239, 1);
					border-top: none;
				}
			}
		}
	}
	
	@media (max-width:1200px) {
		.news {
			.cotain {
				flex-direction: column;
				.swiper-container {
					width: 100%;
					height: 6em;
				}
				.right-ctx {
					margin-left: 0;
					display: block;
					.ctx-item {
						padding: 15px 0;
					}
				}
			}
		}
	}
	
	@media (max-width:768px) {
		.c-news {
			flex-direction: column;
	
			.c-news-item {
				width: 100%;
				.title {
					padding: 5px 0;
					font-size:14px;
					line-height: 20px;
				}
				img {
					height: 6em;
				}
			}
	
			.c-news-item2 {
				margin-top: 0.6em;
			}
		}
	
		.activity {
			.list-box {
				grid-template-columns: repeat(2, 1fr);
	
				.list-item {
					.img {
						img {
							height: 3em;
						}
					}
	
					.title {
						padding: 10px;
						font-size: 16px;
					}
				}
			}
		}
		
		.news {
			.cotain {
				.swiper-container {
					.tle {
						padding: 10px 10px;
					}
				}
				.right-ctx {
					.ctx-item {
						padding: 10px 0;
						.ctx-tle {
							.tle {
								font-size: 14px;
								padding-right: 20px;
							}
						}
						.cota {
							margin-top: 2px;
						}
					}
				}
			}
		}
	}
</style>